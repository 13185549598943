import React, { FC } from 'react';

type MenuItemProps = {
    bgColor: string;
    hoverColor: string,
    classes?: string,
};


const MenuItem: FC<MenuItemProps> = ({ children, bgColor, classes = '', hoverColor }) => (
    <div
        className={`${bgColor} 
      hover:${hoverColor} 
      transition-250
      text-lg 
      text-center
      transition-ease-in-out w-full h-full flex items-center justify-center border-r border-gray-500 lg:text-xl ${classes}`}>
        {children}
    </div>
);

export default MenuItem;