import React, { FC } from 'react';

type Props = {
  name:
    | 'email'
    | 'website'
    | 'arrow_left'
    | 'arrow_right'
    | 'facebook'
    | 'youtube'
    | 'instagram';
  onClick?: any;
  className?: string;
};

const Icon: FC<Props> = ({ name, className = '', onClick }) => {
  switch (name) {
    case 'email':
      return (
        <svg
          onClick={onClick}
          className={`text-gray-600 fill-current ${className}`}
          viewBox="0 0 512 512"
        >
          <path
            d="M334.963,295.151l48.188-166.366h-27.648l-9.6,34.44c-3.985-11.179-10.163-20.702-18.475-28.399
			c-12.962-11.981-30.703-18.048-52.753-18.048c-21.231,0-40.858,5.99-58.3,17.801c-17.067,11.503-31.812,26.47-43.802,44.442
			c-11.87,17.826-21.137,37.726-27.511,59.136c-6.366,21.41-9.6,42.462-9.6,62.566c0,11.23,1.545,22.383,4.574,33.084
			c3.098,11.008,8.081,20.881,14.848,29.329c6.784,8.474,15.454,15.437,25.771,20.702c10.402,5.342,22.946,8.047,37.299,8.047
			c11.895,0,23.125-2.057,33.391-6.118c10.052-3.985,19.174-8.951,27.145-14.814c7.996-5.888,15.036-12.706,20.941-20.25
			c1.118-1.425,2.202-2.859,3.26-4.284c1.519,12.706,6.502,22.707,14.916,29.926c12.066,10.3,27.546,15.539,46.037,15.539
			c21.956,0,41.788-4.634,58.94-13.764c17.084-9.096,31.556-21.734,43.042-37.598c11.358-15.744,20.139-34.62,26.095-56.124
			c5.939-21.385,8.951-44.621,8.951-69.111c0-30.558-5.931-59.383-17.613-85.7c-11.665-26.24-28.245-49.306-49.314-68.531
			c-21.018-19.149-46.225-34.287-74.931-44.996C330.099,5.41,298.313,0,264.35,0c-35.507,0-68.958,6.69-99.447,19.874
			c-30.49,13.184-57.481,31.479-80.213,54.417c-22.75,22.963-40.858,50.261-53.82,81.118
			c-12.971,30.908-19.541,64.546-19.541,99.917s6.212,68.958,18.458,99.84c12.279,30.908,29.807,58.3,52.062,81.468
			c22.306,23.236,49.519,41.813,80.853,55.168C194.086,505.207,229.295,512,267.328,512c41.31,0,79.77-8.747,114.33-26.018
			c34.449-17.246,64.333-40.678,88.781-69.683l4.087-4.838l-19.712-17.067l-4.19,4.966c-21.12,25.02-47.966,45.918-79.77,62.165
			c-31.65,16.145-66.475,24.311-103.526,24.311c-33.69,0-64.93-6.118-92.885-18.15c-27.998-12.058-52.335-28.749-72.311-49.604
			c-20.028-20.881-35.746-45.542-46.72-73.344c-10.991-27.827-16.572-57.899-16.572-89.412c0-29.73,5.675-58.778,16.879-86.349
			c11.204-27.571,26.948-52.284,46.771-73.421c19.831-21.103,43.742-38.127,71.066-50.611
			c27.324-12.459,57.737-18.773,90.436-18.773c29.739,0,57.728,4.813,83.183,14.285c25.404,9.472,47.71,22.861,66.338,39.757
			c18.603,16.922,33.348,37.12,43.853,59.981c10.462,22.886,15.77,48.154,15.77,75.102c0,19.405-2.185,38.426-6.494,56.576
			c-4.258,17.894-10.94,34.065-19.866,48.102c-8.747,13.764-20.164,24.969-33.937,33.314c-13.619,8.243-30.242,12.433-49.408,12.433
			c-10.112,0-18.739-2.509-25.609-7.441c-6.289-4.565-9.353-12.681-9.353-24.866c0-4.966,0.64-11.102,1.92-18.227
			C331.678,307.959,333.214,301.218,334.963,295.151z M321.856,248.721c-4.753,18.347-11.87,36.497-21.146,53.99
			c-9.199,17.297-20.77,32.435-34.381,44.996c-13.15,12.109-27.87,17.997-45.047,17.997c-20.326,0-34.705-5.837-43.955-17.843
			c-9.532-12.373-14.362-27.989-14.362-46.464c0-16.691,2.62-34.662,7.782-53.367c5.137-18.645,12.646-36.045,22.298-51.738
			c9.523-15.437,21.419-28.425,35.354-38.554c13.483-9.805,29.047-14.788,46.276-14.788c9.626,0,17.911,1.604,24.619,4.71
			c6.716,3.183,12.083,7.398,16.384,12.86c4.378,5.589,7.706,12.16,9.916,19.55c2.27,7.62,3.405,15.667,3.405,23.859
			C328.998,215.339,326.592,230.4,321.856,248.721z"
          />
        </svg>
      );

    case 'website':
      return (
        <svg
          onClick={onClick}
          className={`text-gray-600 fill-current ${className}`}
          viewBox="0 0 512.005 512.005"
        >
          <path
            d="M502.124,184.596l-230.4-179.2c-4.625-3.593-10.172-5.393-15.718-5.393c-5.547,0-11.093,1.801-15.718,5.393l-230.4,179.2
                                c-8.636,6.716-12.049,18.176-8.499,28.518c3.55,10.342,13.286,17.289,24.218,17.289h25.6v281.6h153.6v-204.8h102.4v204.8h153.6
                                v-281.6h25.6c10.94,0,20.668-6.946,24.218-17.289C514.164,202.763,510.751,191.312,502.124,184.596z M435.205,204.803v281.6
                                h-102.4v-179.2c0-14.14-11.46-25.6-25.6-25.6h-102.4c-14.14,0-25.6,11.46-25.6,25.6v179.2h-102.4v-281.6h-51.2l230.4-179.2
                                l230.4,179.2H435.205z"
          />
        </svg>
      );
    case 'arrow_left':
      return (
        <svg
          onClick={onClick}
          className={`text-primary fill-current ${className}`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z" />
          <path fill="none" d="M0 0h24v24H0V0z" />
        </svg>
      );
    case 'arrow_right':
      return (
        <svg
          onClick={onClick}
          className={`text-primary fill-current ${className}`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" />
          <path fill="none" d="M0 0h24v24H0V0z" />
        </svg>
      );
    case 'facebook':
      return (
        <svg
          onClick={onClick}
          className={`transition-all transition-250 hover:text-gray-100 text-gray-500 fill-current ${className}`}
          role="img"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Facebook icon</title>
          <path d="M23.9981 11.9991C23.9981 5.37216 18.626 0 11.9991 0C5.37216 0 0 5.37216 0 11.9991C0 17.9882 4.38789 22.9522 10.1242 23.8524V15.4676H7.07758V11.9991H10.1242V9.35553C10.1242 6.34826 11.9156 4.68714 14.6564 4.68714C15.9692 4.68714 17.3424 4.92149 17.3424 4.92149V7.87439H15.8294C14.3388 7.87439 13.8739 8.79933 13.8739 9.74824V11.9991H17.2018L16.6698 15.4676H13.8739V23.8524C19.6103 22.9522 23.9981 17.9882 23.9981 11.9991Z" />
        </svg>
      );
    case 'youtube':
      return (
        <svg
          onClick={onClick}
          className={`transition-all transition-250 hover:text-gray-100 text-gray-500 fill-current ${className}`}
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <title>YouTube icon</title>
          <path d="M23.495 6.205a3.007 3.007 0 0 0-2.088-2.088c-1.87-.501-9.396-.501-9.396-.501s-7.507-.01-9.396.501A3.007 3.007 0 0 0 .527 6.205a31.247 31.247 0 0 0-.522 5.805 31.247 31.247 0 0 0 .522 5.783 3.007 3.007 0 0 0 2.088 2.088c1.868.502 9.396.502 9.396.502s7.506 0 9.396-.502a3.007 3.007 0 0 0 2.088-2.088 31.247 31.247 0 0 0 .5-5.783 31.247 31.247 0 0 0-.5-5.805zM9.609 15.601V8.408l6.264 3.602z" />
        </svg>
      );
    case 'instagram':
      return (
        <svg
          onClick={onClick}
          className={`transition-all transition-250 hover:text-gray-100 text-gray-500 fill-current ${className}`}
          role="img"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Instagram icon</title>
          <path d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z" />
        </svg>
      );
    default:
      return null;
  }
};

export default Icon;
