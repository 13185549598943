import React, { FC, useState } from 'react';
import { useSpring, animated, useTransition } from 'react-spring';

type Props = {
    on: boolean
};

const SubMenu: FC<Props> = ({ children, on }) => {
    const transitions = useTransition(on, null, {
        from: { height: '0rem', opacity: 0, transform: 'scale(1, 0.4)' },
        enter: { height: '12rem', opacity: 1, transform: 'scale(1, 1)' },
        leave: { height: '0rem', opacity: 0, transform: 'scale(1, 0)' },
        // @ts-ignore
        config: (_, state) => {
            switch (state) {
                case "enter": return { friction: 27, tension: 200 }
                case "leave": return { friction: 30, tension: 220 }
            }
        }

    })

    return (
        <>
            {transitions.map(({ item, key, props }) => item && <animated.div className="w-full" key={key} style={props}>{children}</animated.div>)}
        </>
    )

}

export default SubMenu;