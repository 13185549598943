import React, { FC, useState } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import useWindowSize from '../hooks/useWindowSize';

const Course: FC<{ className: string }> = ({ className, children }) => (
  <div className={`w-full h-full flex items-center justify-center text-xl ${className}`}>{children}</div>
);

const KURS_QUERY = graphql`
  {
    allContentfulKurs {
      nodes {
        titel
        kategorie
        id
        order
      }
    }
  }
`;

type Kurs = {
  title: string;
  category: 'kinder' | 'erwachsene';
  color: string;
  order: number;
  displayTitle: string;
};

function getOccurrence(array, value) {
  return array.filter(v => v === value).length;
}
const CoursesSlider: FC = () => {
  const bgColors = ['bg-p1', 'bg-p2', 'bg-p3', 'bg-p4', 'bg-p5', 'bg-p6'];
  const data = useStaticQuery(KURS_QUERY);
  const { width } = useWindowSize();
  const coursesRaw: Kurs[] = data.allContentfulKurs.nodes.map(( node , i: number) => ({
    title: node.titel,
    category: node.kategorie.toLowerCase(),
    color: bgColors[i % bgColors.length],
    displayTitle: node.titel,
    order: node.order
  }));

  const courses = coursesRaw
  .slice()
  .sort((a, b) => a.order - b.order)
  .map(({ category, title, displayTitle, color, order }, i) => {
    let frequency = 0;
    let newDisplayTitle = displayTitle;
    for (const course of coursesRaw) {
      if (course.title === title) {
        frequency++;
      }
    }
    if (frequency > 1) {
      newDisplayTitle = `${title} (${category[0].toUpperCase()}${category.substring(1)})`;
    }
    return { category, title, displayTitle: newDisplayTitle, color: bgColors[i % bgColors.length], order };
  });

  return (
    <>
      {width && (
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={125}
          totalSlides={courses.length}
          visibleSlides={width < 768 ? 3 : width < 1000 ? 4 : width < 1400 ? 5 : 5}
          isPlaying={true}
          className="carousel-height"
        >
          <Slider>
            {width &&
              courses
              .map((c, i) => (
                <Slide key={c.category + c.title} index={i}>
                  <Link
                    to={'/' + c.category + '/' + c.title}
                    style={{ display: 'block', width: '100%', height: '125px' }}
                  >
                    <Course className={c.color}>
                      <p className={`text-center ${width < 768 ? 'text-lg' : ''}`}>{c.displayTitle}</p>
                    </Course>
                  </Link>
                </Slide>
              ))}
          </Slider>
        </CarouselProvider>
      )}
    </>
  );
};

export default CoursesSlider;
