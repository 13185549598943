import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Routes } from '../constants/Routes';
import Logo from './Logo';
import SubMenu from './SubMenu';
import SubMenuImage from './SubMenuImage';
import MenuItem from './MenuItem';

const MenuLink = styled(Link)`
  display: block;
  width: 100%;
`;

type ActiveSubMenuItem = 'about' | 'stundenplan' | 'angebote' | 'kontakt' | null;

const DesktopNavigation: FC<{ location: Routes }> = ({ location }) => {
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [activeSubMenuItem, setActiveSubMenuItem] = useState<ActiveSubMenuItem>(null);
  return (
    <div
      onMouseLeave={() => {
        setSubMenuOpen(false);
        setActiveSubMenuItem(null);
      }}
      className="w-full mx-auto mt-6"
      style={{ maxWidth: '1800px' }}
    >
      <nav className="flex justify-between">
        <>
          <Link to={Routes.HOME} style={{ display: 'block', width: '100%' }}>
            <Logo className="w-full h-16 px-10" />
          </Link>
          <MenuLink to={Routes.NEWS}>
            <MenuItem hoverColor="bg-p6" bgColor={location === Routes.NEWS ? 'bg-p6' : 'bg-white'}>
              News
            </MenuItem>
          </MenuLink>
          <div
            className="w-full cursor-pointer"
            onClick={() => {
              setSubMenuOpen(true);
              setActiveSubMenuItem('about');
            }}
            onMouseEnter={() => {
              setSubMenuOpen(true);
              setActiveSubMenuItem('about');
            }}
          >
            <MenuItem
              hoverColor="bg-p5"
              bgColor={
                location === Routes.PHILOSOPHY ||
                location === Routes.TEAM ||
                location === Routes.UNSERE_LOKALE ||
                activeSubMenuItem === 'about'
                  ? 'bg-p5'
                  : 'bg-white'
              }
            >
              Über Uns
            </MenuItem>
          </div>
          <div
            className="w-full cursor-pointer"
            onClick={() => {
              setSubMenuOpen(true);
              setActiveSubMenuItem('angebote');
            }}
            onMouseEnter={() => {
              setSubMenuOpen(true);
              setActiveSubMenuItem('angebote');
            }}
          >
            <MenuItem
              hoverColor="bg-p4"
              bgColor={
                location === Routes.ERWACHSENE ||
                location === Routes.KINDER ||
                location === Routes.CIE_AHA_PLUS ||
                location === Routes.PREISE ||
                activeSubMenuItem === 'angebote'
                  ? 'bg-p4'
                  : 'bg-white'
              }
            >
              Angebote
            </MenuItem>
          </div>
          <div
            className="w-full cursor-pointer"
            onClick={() => {
              setSubMenuOpen(true);
              setActiveSubMenuItem('stundenplan');
            }}
            onMouseEnter={() => {
              setSubMenuOpen(true);
              setActiveSubMenuItem('stundenplan');
            }}
          >
            <MenuItem
              hoverColor="bg-p3"
              bgColor={
                location === Routes.STUNDENPLAN_WINTERTHUR ||
                location === Routes.STUNDENPLAN_OBERWINTERTHUR ||
                location === Routes.STUNDENPLAN_WIESENDANGEN ||
                activeSubMenuItem === 'stundenplan'
                  ? 'bg-p3'
                  : 'bg-white'
              }
            >
              Stundenplan
            </MenuItem>
          </div>
          <MenuLink to={Routes.GALERIE}>
            <MenuItem hoverColor="bg-p2" bgColor={location === Routes.GALERIE ? 'bg-p2' : 'bg-white'}>
              Galerie
            </MenuItem>
          </MenuLink>
          <div
            className="w-full cursor-pointer"
            onClick={() => {
              setSubMenuOpen(true);
              setActiveSubMenuItem('kontakt');
            }}
            onMouseEnter={() => {
              setSubMenuOpen(true);
              setActiveSubMenuItem('kontakt');
            }}
          >
            <MenuItem
              hoverColor="bg-p1"
              bgColor={
                location === Routes.ANMELDUNGEN || location === Routes.KONTAKT || activeSubMenuItem === 'kontakt'
                  ? 'bg-p1'
                  : 'bg-white'
              }
            >
              Anmeldung / Kontakt
            </MenuItem>
          </div>
        </>
      </nav>
      <SubMenu on={subMenuOpen}>
        {activeSubMenuItem === 'about' && (
          <div className="flex items-center justify-center w-full h-full">
            <Link to={Routes.PHILOSOPHY}>
              <SubMenuImage title="Philosophie" bgColor="bg-p6" />
            </Link>
            <Link to={Routes.TEAM}>
              <SubMenuImage title="Team" bgColor="bg-p3" />
            </Link>
            <Link to={Routes.UNSERE_LOKALE}>
              <SubMenuImage title="Unsere Lokale" bgColor="bg-p1" />
            </Link>
          </div>
        )}
        {activeSubMenuItem === 'angebote' && (
          <div className="flex items-center justify-center w-full h-full max-w-full text-center">
            <Link to={Routes.ERWACHSENE}>
              <SubMenuImage title="Erwachsene" bgColor="bg-p6" />
            </Link>
            <Link to={Routes.KINDER}>
              <SubMenuImage title="Kinder / Jugendliche" bgColor="bg-p5" />
            </Link>
            <Link to={Routes.FOERDERKLASSE}>
              <SubMenuImage title="Förderklasse / Open Class" bgColor="bg-p4" />
            </Link>
            <Link to={Routes.CIE_AHA_PLUS}>
              <SubMenuImage title="Cie Aha! Plus" bgColor="bg-p3" />
            </Link>
            <Link to={Routes.PREISE}>
              <SubMenuImage title="Preise" bgColor="bg-p1" />
            </Link>
          </div>
        )}
        {activeSubMenuItem === 'stundenplan' && (
          <div className="flex items-center justify-center w-full h-full">
            <Link to={Routes.STUNDENPLAN_WINTERTHUR}>
              <SubMenuImage title="Winterthur" bgColor="bg-p6" />
            </Link>
            <Link to={Routes.STUNDENPLAN_OBERWINTERTHUR}>
              <SubMenuImage title="Oberwinterthur" bgColor="bg-p3" />
            </Link>
            <Link to={Routes.STUNDENPLAN_WIESENDANGEN}>
              <SubMenuImage title="Wiesendangen" bgColor="bg-p1" />
            </Link>
          </div>
        )}
        {activeSubMenuItem === 'kontakt' && (
          <div className="flex items-center justify-end w-full h-full">
            <Link to={Routes.ANMELDUNGEN}>
              <SubMenuImage title="Anmeldung" bgColor="bg-p4" />
            </Link>
            <Link to={Routes.KONTAKT}>
              <SubMenuImage
                title={
                  <span>
                    Kontakt / Schnupper-
                    <br />
                    lektionen
                  </span>
                }
                bgColor="bg-p1"
              />
            </Link>
          </div>
        )}
      </SubMenu>
    </div>
  );
};

export default DesktopNavigation;
