export enum Routes {
  HOME = '/',
  NEWS = '/news',
  PHILOSOPHY = '/philosophie',
  ANMELDUNGEN = '/anmeldungen',
  TEAM = '/team',
  UNSERE_LOKALE = '/unsere_lokale',
  STUNDENPLAN_OBERWINTERTHUR = '/stundenplan_oberwinterthur',
  STUNDENPLAN_WINTERTHUR = '/stundenplan_winterthur',
  STUNDENPLAN_WIESENDANGEN = '/stundenplan_wiesendangen',
  GALERIE = '/galerie',
  KONTAKT = '/kontakt',
  ERWACHSENE = '/erwachsene',
  KINDER = '/kinder',
  PREISE = '/preise',
  CIE_AHA_PLUS = '/cie_aha_plus',
  FOERDERKLASSE = '/foerderklasse',
}
