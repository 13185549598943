import { Routes } from '../constants/Routes';
import { useState, useLayoutEffect } from 'react';
const useBgColor = (location: Routes) => {
  const [bgColor, setBgColor] = useState('');
  useLayoutEffect(() => {
    switch (location) {
      case Routes.NEWS:
        setBgColor('bg-p6');
        break;
      case Routes.PHILOSOPHY:
      case Routes.TEAM:
      case Routes.UNSERE_LOKALE:
        setBgColor('bg-p5');
        break;
      case Routes.ERWACHSENE:
      case Routes.KINDER:
      case Routes.CIE_AHA_PLUS:
      case Routes.FOERDERKLASSE:
      case Routes.PREISE:
        setBgColor('bg-p4');
        break;
      case Routes.STUNDENPLAN_OBERWINTERTHUR:
      case Routes.STUNDENPLAN_WIESENDANGEN:
      case Routes.STUNDENPLAN_WINTERTHUR:
        setBgColor('bg-p3');
        break;
      case Routes.GALERIE:
        setBgColor('bg-p2');
        break;
      case Routes.KONTAKT:
      case Routes.ANMELDUNGEN:
        setBgColor('bg-p1');
        break;
      default:
        setBgColor('bg-white');
    }
  }, [location]);
  return bgColor;
};
export default useBgColor;
