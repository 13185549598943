import React, { FC } from 'react';
import Icon from './Icon';

type Props = {};

const Footer: FC<Props> = () => {
  return (
    <footer className="bg-gray-900 h-16 text-gray-500 flex items-center justify-center">
      <p className="mr-4 px-4">
        &copy; {new Date().getFullYear()} <a className="underline" href="mailto:stefan@bassplayer.ch">Stefan Guggisberg</a> &{' '}
        <a className="underline" href="mailto:kira.stahel@gmail.com">Kira Stahel</a>
      </p>
      <div className="flex">
        <a href="https://www.facebook.com/pg/tanzstudioaha" target="_blank">
          <Icon className="w-8 h-8 mr-4" name="facebook" />
        </a>
        <a href="https://www.instagram.com/ahatanzstudio/" target="_blank">
          <Icon className="w-8 h-8 mr-4" name="instagram" />
        </a>
        <a href="https://www.youtube.com/channel/UCG4gYGKPPreJs4y0wh6lLIA" target="_blank">
          <Icon className="w-8 h-8 mr-4" name="youtube" />
        </a>
      </div>
    </footer>
  );
};
export default Footer;
