import React, { FC } from 'react';

type Props = {
  title: string | React.ReactNode;
  bgColor: string;
};

const SubMenuImage: FC<Props> = ({ title, bgColor }) => {
  return (
    <div
      className={`flex items-center justify-center w-32 h-32 mx-4 border-4 border-transparent lg:w-40 lg:h-40 xl:mx-12 hover:border-p5 transition-all transition-250 ${bgColor}`}
    >
      <h3 className="text-md">{title}</h3>
    </div>
  );
};

export default SubMenuImage;
