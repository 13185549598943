import React, { FC } from 'react';
import './layout.css';
import DesktopNavigation from './DesktopNavigation';
import MobileNavigation from './MobileNavigation';
import styled from 'styled-components';
import { Routes } from '../constants/Routes';
import CoursesSlider from './CoursesSlider';
import useWindowSize from '../hooks/useWindowSize';
import useBgColor from '../hooks/useBgColor';
import Footer from './Footer';
import 'lightbox-react/style.css';
import './markdown-styles.scss';
import 'pure-react-carousel/dist/react-carousel.es.css';

const Grid = styled.div`
  display: grid;
  grid-template-rows: 1fr 10fr;
`;

const HomePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
`;

const Layout: FC<{ location: Routes }> = ({ children, location }) => {
  const { width } = useWindowSize();
  const bgColor = useBgColor(location);

  return (
    <>
      {width && (
        <>
          {location === Routes.HOME ? (
            <HomePageContainer>
              {width > 768 ? <DesktopNavigation location={location} /> : <MobileNavigation location={location} />}
              {children}
              <CoursesSlider />
            </HomePageContainer>
          ) : (
            <Grid
              className={`h-screen antialiased text-gray-900 ${
                location === Routes.PREISE ? 'overflow-x-auto' : 'overflow-x-hidden'
              }`}
            >
              {width > 768 ? <DesktopNavigation location={location} /> : <MobileNavigation location={location} />}
              <main className={`${bgColor} flex align-middle justify-center w-full`}>
                <div className="bg-white w-full mt-12 pb-10 md:mx-10 md:mt-6 shadow-xl" style={{ maxWidth: '1800px' }}>
                  {children}
                </div>
              </main>
              <Footer />
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default Layout;
