import React, { FC, useState, useLayoutEffect } from 'react';
import Logo from './Logo';
import { Routes } from '../constants/Routes';
import useBgColor from '../hooks/useBgColor';
import { Link } from 'gatsby';
import { useSpring, animated } from 'react-spring';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

type Props = {
  location: Routes;
};

const MenuItem: FC<{ to: Routes; name: string; handleMenuClose: () => void; marginLeft?: boolean }> = ({
  to,
  name,
  handleMenuClose,
  marginLeft = false,
}) => (
  <Link className={`cursor-pointer block text-3xl mb-2 ${marginLeft ? 'ml-4' : ''}`} to={to} onClick={handleMenuClose}>
    {name}
  </Link>
);

const MobileNavigation: FC<Props> = ({ location }) => {
  const bgColor = useBgColor(location);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const handleMenuClose = () => setIsNavOpen(false);
  const props = useSpring({
    transform: !isNavOpen ? 'translateY(-200vh)' : 'translateY(0vh)',
    opacity: !isNavOpen ? 0.5 : 1,
  });
  return (
    <nav className={`${bgColor}`}>
      <div className={`bg h-16 flex justify-between items-center fixed w-full z-50 ${bgColor} `}>
        <Link to={Routes.HOME}>
          <Logo className="h-12 ml-4" />
        </Link>
        <svg
          onClick={() => setIsNavOpen(!isNavOpen)}
          className="mr-2 cursor-pointer text-gray-900 fill-current"
          height="48px"
          viewBox="0 0 32 32"
          width="48px"
        >
          <path d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z" />
        </svg>
      </div>

      <animated.div
        className={`fixed z-10 w-full mt-16 flex items-center justify-center overflow-y-auto ${bgColor}`}
        style={{ ...props, height: 'calc(100vh - 4rem)' }}
      >
        <div className="p-8 flex flex-col">
          <MenuItem to={Routes.NEWS} name="News" handleMenuClose={handleMenuClose} />
          <Accordion>
            <AccordionItem>
              <AccordionItemHeading style={{ marginLeft: '-21px' }} className="font-bold text-3xl">
                <AccordionItemButton>Über Uns</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <MenuItem marginLeft to={Routes.UNSERE_LOKALE} name="Unsere Lokale" handleMenuClose={handleMenuClose} />
                <MenuItem marginLeft to={Routes.PHILOSOPHY} name="Philosophie" handleMenuClose={handleMenuClose} />
                <MenuItem marginLeft to={Routes.TEAM} name="Team" handleMenuClose={handleMenuClose} />
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading style={{ marginLeft: '-21px' }} className="font-bold text-3xl">
                <AccordionItemButton>Angebote</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <MenuItem marginLeft to={Routes.KINDER} name="Kinder / Jugendliche" handleMenuClose={handleMenuClose} />
                <MenuItem marginLeft to={Routes.ERWACHSENE} name="Erwachsene" handleMenuClose={handleMenuClose} />
                <MenuItem marginLeft to={Routes.CIE_AHA_PLUS} name="cie. aha! plus" handleMenuClose={handleMenuClose} />
                <MenuItem marginLeft to={Routes.PREISE} name="Preise" handleMenuClose={handleMenuClose} />
                <MenuItem
                  marginLeft
                  to={Routes.FOERDERKLASSE}
                  name="Förderklasse / Open Class"
                  handleMenuClose={handleMenuClose}
                />
              </AccordionItemPanel>
              <AccordionItem>
                <AccordionItemHeading style={{ marginLeft: '-21px' }} className="font-bold text-3xl">
                  <AccordionItemButton>Stundenplan</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <MenuItem marginLeft to={Routes.STUNDENPLAN_WINTERTHUR} name="Winterthur" handleMenuClose={handleMenuClose} />
                  <MenuItem
                    marginLeft
                    to={Routes.STUNDENPLAN_OBERWINTERTHUR}
                    name="Oberwinterthur"
                    handleMenuClose={handleMenuClose}
                  />
                  <MenuItem
                    marginLeft
                    to={Routes.STUNDENPLAN_WIESENDANGEN}
                    name="Wiesendangen"
                    handleMenuClose={handleMenuClose}
                  />
                </AccordionItemPanel>
              </AccordionItem>
            </AccordionItem>
          <MenuItem to={Routes.GALERIE} name="Galerie" handleMenuClose={handleMenuClose} />
          <AccordionItem>
            <AccordionItemHeading style={{ marginLeft: '-21px' }} className="font-bold text-3xl">
              <AccordionItemButton>Anmeldung / Kontakt</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <MenuItem marginLeft to={Routes.ANMELDUNGEN} name="Anmeldung" handleMenuClose={handleMenuClose} />
              <MenuItem marginLeft to={Routes.KONTAKT} name="Kontakt" handleMenuClose={handleMenuClose} />
            </AccordionItemPanel>
          </AccordionItem>
          </Accordion>
        </div>
      </animated.div>
    </nav>
  );
};

export default MobileNavigation;
