import { useState, useEffect } from "react"

let defaultHeight
let defaultWidth

if (typeof window !== `undefined`) {
    defaultHeight = window.innerHeight
    defaultWidth = window.innerWidth
}

const useWindowSize = () => {
    const [dimensions, setDimensions] = useState({
        height: defaultHeight,
        width: defaultWidth,
    })

    useEffect(() => {
        const handler = () => setDimensions({
            height: window.innerHeight,
            width: window.innerWidth,
        })

        window.addEventListener(`resize`, handler)
        return () => window.removeEventListener(`resize`, handler)
    }, [])

    return dimensions
}

export default useWindowSize;